<template>

<div class="options_label">{{ label }}</div>
<div class="the_options">
    <div v-for="opt in options" @click="selected(opt)" :class="{current_item: opt===current}">
        {{ opt }}
    </div>
</div>

</template>

<script>

export default {
    emits: ["changed_selection"],
    props: ["options", "label", "default_value"],
    data() {
        return {
            current: null
        }
    },
    methods: {
        selected(thing){
            this.current = thing
            this.$emit("changed_selection", thing)
        }
    },
    mounted() {
        this.selected(this.default_value);
    }
}

</script>

<style>

.the_options {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 2vh;
}

.the_options div {
    flex-basis: 20%;
    flex-grow: 1;
    height: min(10vmin, 50px);
    line-height: min(10vmin, 50px);
    text-align: center;
    background-color: white;
    /* border: 1px solid #D3D5DB; */
    border-radius: 5px;
    cursor: pointer;
}

.options_label {
    margin-bottom: 6px;
}

.current_item{
    background-color: #D4D6E1 !important;
}

</style>