<template>

{{ error }}

</template>
<script>

import {api} from "../../utils/api.js";
import {store} from "../../utils/store.js";

export default {
    data() {
        return {
            email: this.$route.query.email,
            token: this.$route.query.token,
            error: "",
        }
    },
    methods: {
        
    },
    async mounted() {
        let res = await api.post(
            "/auth/verify",
            {
                'email': this.email,
                'token': this.token,
            }
        );
        let j = await res.json();
        if (res.status !== 200) {
            this.error = j["detail"];
        } else {
            store.handle_login(j);
            this.$router.push({name: "Reports"})
        }
    }
}
</script>
<style>


</style>
