import { reactive } from 'vue';

export const store = reactive({
    bearer: localStorage.getItem("bearer"),
    stripe_subscription_end: localStorage.getItem("stripe_subscription_end"),
    stripe_subscription_id: localStorage.getItem("stripe_subscription_id"),
    stripe_subscription_ecgs: localStorage.getItem("stripe_subscription_ecgs"),

    handle_login(j) {
        this.bearer = j["bearer"];

        if (this.bearer !== null){
            localStorage.setItem("bearer", this.bearer);
        }

        this.handle_sub(j);

        gtag('config', 'GTM-MN55HMVX', { // This ID is all over the place, don't replace it in just one of them.
            'user_id': j["user_id"],
        });

        this.set_cookie_consent(j["consent_to_analytics"]);
    },

    handle_logout() {
        this.bearer = null;
        this.stripe_subscription_end = null;
        this.stripe_subscription_id = null;
        this.stripe_subscription_ecgs = null;
        localStorage.clear();
    },

    handle_sub(j){
        this.stripe_subscription_end = j["stripe_subscription_end"];
        this.stripe_subscription_id = j["stripe_subscription_id"];
        this.stripe_subscription_ecgs = j["stripe_subscription_ecgs"];
        localStorage.setItem("user_id", j["user_id"]);

        if (this.stripe_subscription_end !== null){
            localStorage.setItem("stripe_subscription_end", this.stripe_subscription_end);
        } else {
            localStorage.removeItem("stripe_subscription_end");
        }
        if (this.stripe_subscription_id !== null){
            localStorage.setItem("stripe_subscription_id", this.stripe_subscription_id);
        } else {
            localStorage.removeItem("stripe_subscription_id");
        }
        if (this.stripe_subscription_ecgs !== null){
            localStorage.setItem("stripe_subscription_ecgs", this.stripe_subscription_ecgs);
        } else {
            localStorage.removeItem("stripe_subscription_ecgs");
        }
    },

    handle_unsub(){
        this.stripe_subscription_id = null;
        localStorage.removeItem("stripe_subscription_id");
    },

    is_logged_in() {
        return this.bearer !== null && this.bearer !== undefined;
    },

    is_premium() {
        return this.stripe_subscription_id !== null && this.stripe_subscription_id !== undefined;
    },

    remaining_ecgs() {
        return Math.max(0, this.stripe_subscription_ecgs);
    },

    try_load_cookie_consent(){
        let current_consent = localStorage.getItem("cookie-consent");
        if (current_consent !== null){
            this.set_cookie_consent(current_consent);
        }
    },

    set_cookie_consent(consent) {
        window['ga-disable-GTM-MN55HMVX'] = !consent; // This ID is all over the place, don't replace it in just one of them.
        sessionStorage.setItem("cookie-consent", consent);
        if (this.is_logged_in()) {
            localStorage.setItem("cookie-consent", consent);
        }
    },

    try_set_user_id() {

    }
});
