import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import * as Sentry from '@sentry/vue'

const app = createApp(App)

if (process.env.VUE_DEBUG !== "TRUE") {
  Sentry.init({
    app,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    tracesSampleRate: process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE,
  });
}

app.use(router).mount('#app')
