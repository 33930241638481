<template>
  <router-view></router-view>
  <ConsentSettings/>
</template>

<script>

import {store} from "./utils/store.js";
import ConsentSettings from "./components/ConsentSettings.vue"

export default {
  name: 'App',
  components: {ConsentSettings},
  data() {
    return {
      store: store,
    }
  },
  watch:{
    $route (to, from){
      const public_page = ["Login", "Reset", "Verify"].includes(to.name)
      const is_logged_in = store.is_logged_in()
      if (public_page && is_logged_in){
        this.$router.push({name: "Reports"});
      }
      if (!public_page && !is_logged_in){
        this.$router.push({name: "Login"});
      }
    }
  },
  mounted() {
    store.try_load_cookie_consent();
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

body {
  margin: 0;
  background-color: #F2F4F7;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  font-family: 'Open Sans', sans-serif;
}

.header {
  position: fixed;
  top: 0;
  z-index: 10;

  width: 100%;
  padding: 5px 15px 5px 15px;
  box-sizing: border-box; /* Makes width account for padding */

  display: flex;
  justify-content: space-between;
  line-height: min(10vmin, 50px);
  flex-wrap: wrap;
  background-color: white;
}

.header div {
  cursor: pointer;
}

.post-header-offset {
  margin-top: min(10vmin + 30px, 80px);
}

.user_email {
    color: #ddd;
}

button, input {
  -webkit-appearance: none; /* Prevents buttons from being round on iOS */
  --webkit-border-radius: 4px; /* Prevents buttons from being round on iOS */
  border: 0 none; /* Remove borders from buttons */
  background: none;
}
button:not(:disabled) {
  cursor: pointer;
}

select {
  color: black;
  background-color: white;
}

html {
  touch-action: manipulation; /* Prevents zooming on double tap https://endtimes.dev/you-can-use-css-to-remove-the-double-tap-zoom-feature-on-ios/*/
}

</style>
