<template>

<div class="loading-holder">
    <div class="loading">
        Loading...
    </div>
</div>

</template>
<script>

export default {
    async mounted() {
        window.setTimeout(() => this.$router.push({name: "Premium"}), 2000);
    }
}

</script>
<style>
.loading-holder {    
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading {
    font-size: 1.6em;
}

</style>
