<template>

<div class="eol">
<div class="eolrow">PMcardio Digitize is going to be deprecated from January 31st 2025 and will shut down on March 1st 2025.</div>
<div class="eolrow">To continue using ECG Digitization services from Powerful Medical, consider registering on <a href="https://www.powerfulmedical.com/pmcardio-individuals/" target="_blank">PMcardio for Individuals</a>.</div>
</div>

</template>
<script>

export default {};

</script>
<style>

.eol {
    background-color: rgb(255, 0, 0);
    color: white;
    font-weight: bold;
    padding: 0.5em;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 0.3em;
}

.eol a {
    color: #dddddd
}

.eol a:hover {
    color: #bbbbbb
}

</style>