<template>
  <button type="button" class="consent_settings" @click="openModal"
    v-if="['Login', 'Premium'].includes($route.name)">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
        d="M8 10.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"></path>
      <path stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
        d="M15.5 9V7l-2.177-.363a5.463 5.463 0 0 0-.595-1.437l1.283-1.796-1.415-1.414L10.8 3.272a5.463 5.463 0 0 0-1.437-.595L9 .5H7l-.363 2.177a5.463 5.463 0 0 0-1.437.595L3.404 1.99 1.99 3.404 3.272 5.2a5.463 5.463 0 0 0-.595 1.437L.5 7v2l2.177.363c.131.512.332.994.595 1.437L1.99 12.596l1.414 1.414L5.2 12.728c.443.263.925.464 1.437.595L7 15.5h2l.363-2.177a5.463 5.463 0 0 0 1.437-.595l1.796 1.283 1.414-1.414-1.282-1.797c.263-.443.464-.925.595-1.437L15.5 9z">
      </path>
    </svg>
    Settings
  </button>

  <div v-if="popup" class="consent-popup">
    <button @click="closeModal" type="button" class="close-button" title="Close modal">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="m17.5 6.5-11 11m0-11 11 11"></path>
      </svg>
    </button>
    <header>We use cookies</header>

    <p>We use some essential cookies to make this website work.</p>

    <p>
      We'd also like to use some additional cookies so we can understand how
      you use our website, improve the product, and show you relevant ads.
    </p>

    <p>
      You can change your choice at any time. For more information, see our
      <a href="https://www.powerfulmedical.com/legal/general-privacy" target="_blank">Privacy Notice</a>.
    </p>

    <div class="consent-buttons">
      <button @click="set_analytics_consent(false)">Reject optional cookies</button>
      <button @click="set_analytics_consent(true)" class="accept-button">
        Accept optional cookies
      </button>
    </div>
  </div>
</template>

<script>
import { store } from '../utils/store.js';
import { api } from '../utils/api.js';


export default {
  data() {
    return {
      popup: false,
    };
  },
  methods: {
    set_analytics_consent(consent) {
      if (store.is_logged_in()) {
        api.auth_post(
            "/auth/set_consent",
            {
                consent: consent,
            },
        );
      };
      store.set_cookie_consent(consent);
      this.closeModal();
    },
    openModal() {
      this.popup = true;
    },
    closeModal() {
      this.popup = false;
    },
    open_if_needed() {
      this.popup = sessionStorage.getItem('cookie-consent') === null;
    }
  },
  watch:{
    $route (to, from){
      if(["Login", "Reports"].includes(to.name)) {
        this.open_if_needed();
      }
    },
  },
};
</script>

<style>
.consent_settings {
  align-items: center;
  bottom: 16px;
  display: flex;
  gap: 4px;
  left: 16px;
  position: fixed;
  color: black;
}

.consent-popup {
  width: 70vw;
  max-width: 560px;
  max-height: 100%;
  overflow-y: auto;
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  box-shadow: 0 0 0 1000000px rgba(2, 8, 39, 0.33);
}

.consent-popup header {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  margin: 0;
}

.consent-popup p {
  font-size: 14px;
}

.close-button {
  width: 24px;
  height: 24px;
  float: right;
}

.consent-buttons {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

@media (max-width: 1000px) {
  .consent-buttons {
    flex-direction: column;
  }
}

.consent-buttons button {
  padding: 10px;
  border: 1pt solid black;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  background-color: white;
  color: black;
  cursor: pointer;
}

.consent-buttons .accept-button {
  background-color: #0033cc;
  color: white;
  border: 1pt solid #0033cc;
}
</style>
