<template>
    <div class="pb">
        <h1>Convert ECGs into<br/>a digital waveform using AI</h1>
        <img class="main-element" src="/img/banner/main_element.png"/>
        <img class="icon-left" src="/img/banner/icon_left.png"/>
        <img class="ecg-right" src="/img/banner/ECG_right.png"/>
        <img class="patternleft" src="/img/banner/patternleft.png"/>
        <img class="patternright" src="/img/banner/patternright.png"/>
    </div>
</template>
<script>

</script>
<style>

.pb {
    width: 100%;
    height: 100%;
    background: rgb(2,0,36);
    background: linear-gradient(45deg, rgba(2,0,36,1) 0%, rgba(255,255,255,1) 0%, rgba(0,51,204,1) 100%);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.pb h1 {
    position: absolute;
    top: 10%;
    margin: 0 5% 0 5%;
    color: white;
    z-index: 7;
    font-size: 3vh;
    text-align: center;
}

.pb .main-element {
    max-width: 80%;
    max-height: 80%;
    margin: 10%;
    z-index: 3;
}

.pb .icon-left {
    position: absolute;
    top: 30%;
    right: 40%;
    max-width: 10%;
    max-height: 10%;
    z-index: 5;
}

.pb .ecg-right {
    position: absolute;
    bottom: 10%;
    right: 5%;
    max-width: 30%;
    max-height: 7%;
    z-index: 5;
}

.pb .patternleft {
    position: absolute;
    left: 50%;
    bottom:0%;
    max-width: 50%;
    max-height: 50%;
}

.pb .patternright {
    position: absolute;
    right: 0%;
    top:0%;
    max-width: 50%;
    max-height: 50%;
}


</style>
