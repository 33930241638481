<template>
<div class="header">
    <div @click="$router.push({name: 'Reports'})">&lt; Back</div>
</div>
<div class="post-header-offset"></div>
<div v-if="!store.is_premium()">
    <EOLBanner/>
    <h2 style="text-align: center;">Premium Subscriptions are no longer available.</h2>
</div>
<div v-if="store.is_premium()">
    <div class="current-plan" v-for="sub in subscriptions" v-bind:id="sub['stripe_subscription_id']">
        <div class="premium-title">You are a Premium User</div>
        <div class="label">Price</div>
        <div class="value">{{ sub["price"]/100 }} {{ sub["currency"] }} / {{ sub["interval"] }}</div>
        
        <div class="label">
            <p>Next payment</p>
        </div>
        <div class="value">{{ moment.unix(sub["current_period_end"]).format("MMM DD YYYY") }}</div>
        <div class="label">
            <p><i>Subscriptions will only be renewed until the 31st of January 2025. PMcardio Digitize will remain available up to the 1st of March 2025, but when your February payment date comes instead of being charged your subscription will automatically end without charging you. Your ECGs will remain available for viewing and download until the 1st of March 2025.</i></p>
        </div>

        <span class="cancel_sub"><span @click="cancel">Cancel Subscription</span></span>
        <div class="help-text">(You can cancel your subscription ahead of time, but note that it is no longer possible to start new subscriptions)</div>
    </div>
</div>
<div style="margin: 1em" v-if="store.is_premium()">
<EOLBanner/>
</div>
<div class="help-text">
    <p>After almost two years and thousands of digitized ECGs, PMcardio Digitize is being decommissioned. For those who wish to use our digitization services we have two alternative products that could fit your needs:</p>
    <p><b><a href="https://www.powerfulmedical.com/pmcardio-individuals/" target="_blank">PMcardio for Individuals</a></b> is our more feature-complete application for not only digitizing but also diagnosing ECGs from an image.</p>
    <p>The <b>Powerful Medical AI API</b> is our solution for partners that require larger volume digitization or diagnostics of ECG records. The API is available in the United States as well as in the European Union to ensure complete compliance to data residency requirements you may have. Check the
        <a href="https://us-east-1.pm-api.com/v0/docs" target="_blank">US API Documentation</a> and the 
        <a href="https://eu-west-1.pm-api.com/v0/docs" target="_blank">EU API Documentation</a></p>
</div>
<div class="help-text">
    For any questions or feedback, please get in touch with us via <a href="mailto:help@powerfulmedical.com">help@powerfulmedical.com</a>.
</div>
</template>

<script>

import {store} from "../utils/store.js"
import {api} from "../utils/api.js"

import EOLBanner from "../components/EOLBanner.vue"
import moment from 'moment';

export default {
    components: {EOLBanner},
    data () {
        return {
            store: store,
            subscriptions: [],
            moment: moment,
            checkout_url: "",
            checkout_in_progress: false,
            plan_button_text: "Claim offer",
        }
    },
    methods: {
        async checkout() {
            this.checkout_in_progress = true;
            this.plan_button_text = "checkout in progress...";
            let res = await api.auth_get("/billing/checkout");
            let j = await res.json()
            this.checkout_url = j["checkout"];
            window.location.href = this.checkout_url;
        },

        async cancel() {
            if(confirm("Are you sure you wish to cancel your subscription?")) {
                await api.auth_post("/billing/unsubscribe");
                store.handle_unsub();
                location.reload();
            }
        }
    },
    async mounted() {
        let res = await api.auth_get("/billing/subscriptions");
        this.subscriptions = await res.json()
        if(this.subscriptions.length > 0){
            store.handle_sub(this.subscriptions[0]);
        }
    }
};
</script>
<style>

.plans-holder {
    display: flex;
    justify-content: center;
}

.plan-holder {
    text-align: center;
    padding: 30px min(5vw, 120px) 30px min(5vw, 120px);
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 0 30px #dddddd;
    color: black;
    max-width: vw;
}

.plan-holder h2 {
    margin: 0;
    padding: 0;
}

.plan-holder .special-offer {
    display: inline-block;
    background-color: #E2D6FF;
    border-radius: 5px;
    color: #260477;
    font-weight: bold;
    padding: 0 8px 0 8px;
}

.plan-holder .plan-price {
    font-size: 3em;
    font-weight: bold;
}

.plan {
    background-color: #1130BD;
    color: white;
    width: min(80%, 500px);
    margin: 10px auto 10px auto;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
    font-weight: bold;
    cursor: pointer;
}

.current-plan {
    background-color: white;
    border-radius: 15px;
    margin: 0 auto 0 auto;
    padding: clamp(5vmin, 50px, 10vmin);
    width: min(70vw, 700px);
    box-shadow: 0px 0px 40px rgba(0,0,0,0.1);
}

.current-plan .label {
    color: #6D7174;
    font-weight: bold;
}

.current-plan .value {
    color: #6D7174;
    border-bottom: 1pt solid #cbcbcb;
    margin-top: clamp(0.5vh, 5px, 3vh);
    padding-bottom: clamp(0.5vh, 5px, 3vh);
    margin-bottom: clamp(2vh, 5px, 3vh);
}

.cancel_sub {
    color: #0033CC;
    display: block;
    text-align: center;
    text-decoration: underline;
    margin: 0 auto 0 auto;
}

.cancel_sub span {
    cursor: pointer;
}

.help-text {
    margin: clamp(0.5vh, 15px, 3vh) auto 0 auto;
    -webkit-user-select: text; /* Safari */
    -ms-user-select: text; /* IE 10 and IE 11 */
    user-select: text; /* Standard syntax */
    text-align: center;
    padding: 0 5vw 0 5vw;
}

.current-plan .premium-title {
    text-align: center;
    font-size: 1.3em;
    margin-bottom: 0.3em;
}

</style>
