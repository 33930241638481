<template>

    <div class="outer-shell">
        <div class="login-pane">
            <div class="login-pane-title">{{ title }}</div>
            <form @submit.prevent="proceed" class="authentication-form">
                <label>Email</label>
                <input type="email" autocomplete="email" class="form_input" placeholder="Email" required v-model="email" @input="reset"/>
                <div v-if="state.startsWith('login')">
                    <label>Password</label>
                    <input type="password" class="form_input" placeholder="Password" autocomplete="password" :passwordrules="password_rules" required v-model="password"/>
                </div>
                <div v-if="state.startsWith('register')">
                    <div v-if="!state.startsWith('register.')">
                        <p style="text-align: center;">Could not find a user with that email address. Unfortunately, registration is no longer available on PMcardio Digitze.</p>
                        <p class="alternative">If you wish to digitze ECG recordings, check out <a href="https://www.powerfulmedical.com/pmcardio-individuals/" target="_blank">PMcardio for Individuals!</a></p>
                    </div>
                </div>
                <button :disabled="lock_button" type="submit" class="login_button" v-if="!state.startsWith('register')">
                    {{ button_text }}
                </button>

                <div class="responses">
                    <div class="error" v-if="state.startsWith('invalid_email')">
                        The email is not a valid email!
                    </div>
                    <div class="error" v-if="state.startsWith('login.failed')">
                        Incorrect password! If you forgot your password and want to reset it, <span @click="password_reset" style="cursor: pointer"><u>click here</u></span>.
                    </div>
                    <div class="success" v-if="state.startsWith('register.verify')">
                        We have sent you a verification email to {{ email }}. Check your inbox and click the link to verify your email!
                    </div>
                    <div class="success" v-if="state.startsWith('login.reset')">
                        We have sent you an email with instructions on how to reset your password. Check your inbox.
                    </div>
                    <div class="error" v-if="state.startsWith('register.already')">
                        This email address is already registered!
                    </div>
                    <div class="error" v-if="error">
                        {{ error }}
                    </div>
                </div>
            </form>
        </div>
        <div class="promo-pane">
            <PromoBanner/>
        </div>
    </div>
</template>

<script>

import {api} from "../utils/api.js"
import {store} from "../utils/store.js";
import PromoBanner from "../components/PromoBanner.vue";

export default {
    components: {PromoBanner},
    data() {
        return {
            email: "",
            password: "",
            password_verify: "",
            state: "start",
            password_rules: "required: upper; required: lower; required: digit; minlength: 12;",
            error: "",
            store: store,
            red_password_hints: false,
            lock_button: false,
            last_inserted_email: "",
            attribution_other: false,
            attribution_selected: "Facebook/Instagram",
            attribution_custom: "",
            consent_to_terms: false,
            consent_to_marketing: false,
        }
    },
    methods: {
        async proceed() {
            this.error = "";
            if (this.lock_button) return;
            this.lock_button = true;
            try {
                if (this.state.startsWith('start')) await this.check_email();
                else if (this.state.startsWith('login')) await this.login_flow();
                else if (this.state.startsWith('register')) await this.registration_flow();
            } finally {
                this.lock_button = false;
            }
        },
        reset() {
            if (this.email === this.last_inserted_email) {
                // This prevents a reset when a password manager replaces the email with itself.
                return;
            }
            this.last_inserted_email = this.email;
            this.password = ""
            this.password_verify = ""
            this.state = "start"
            this.link = ""
            this.red_password_hints = false;
            this.error = "";
            this.consent_to_terms = false;
            this.consent_to_marketing = false;
        },
        async password_reset() {
            let res = await api.post(
                "/auth/request_password_reset",
                {'email': this.email},
            );
            if (res.status == 200){
                this.state = "login.reset";
            } else if (res.status == 406) {
                this.error = "This email is not verified yet. You cannot reset the password of a user that has not completed the registration flow.";
            } else if (res.status === 429) {
                this.error = "Too many requests! Please wait a while!";
            } else {
                this.error = "Failed to request a password reset.";
            }
        },
        async check_email(){
            let res = await api.post(
                "/auth/check",
                {'email': this.email},
            );
            let j = await res.json();
            if (res.status === 422) {
                this.state = "invalid_email";
            } else if(j["exists"]){
                this.state = "login";
            } else {
                this.state = "register";
            }
        },
        async login_flow(){
            let res = await api.post(
                "/auth/login",
                {
                    'email': this.email,
                    'password': this.password,
                },
            );
            let j = await res.json();
            if (res.status === 200){
                store.handle_logout();
                store.handle_login(j);
                this.$router.push({name: "Reports"})
            } else if (res.status === 401) {
                this.state = "login.failed";
            } else {
                this.error = j["detail"];
            }

        },
        async registration_flow() {
            if (this.password_errors.length > 0){
                this.red_password_hints = true;
                return;
            }
            let data = {
                'email': this.email,
                'password': this.password,
                'consent_to_terms': this.consent_to_terms,
                'consent_to_marketing': this.consent_to_marketing,
                'self_attribution': this.compute_attribution(),
            }
            if (sessionStorage.getItem("cookie-consent") === "true") {
                data['consent_to_analytics'] = true;
            }
            let res = await api.post("/auth/register?" + new URLSearchParams(this.$route.query), data);
            let j = await res.json();
            if (res.status === 200) {
                this.state = "register.verify";
            } else if (res.status === 409){
                this.state = "register.already";
            } else if (res.status === 429){
                this.error = "Too many requests! Please wait a while!";
            } else {
                this.error = j["detail"];
            }
        },
        compute_attribution_other() {
            this.attribution_other = this.attribution_selected === "Other";
        },
        compute_attribution() {
            if (this.attribution_other) {
                return this.attribution_custom;
            }
            return this.attribution_selected;
        }
    },
    computed: {
        title() {
            if (this.state.startsWith('register')) return "Register";
            else if (this.state.startsWith('login')) return "Login";
            return "Login / Register to PMcardio Digitize";
        },
        button_text() {
            if (this.lock_button) return "Please wait...";
            else if (this.state.startsWith('register')) return "Register";
            else if (this.state.startsWith('login')) return "Login";
            return "Continue with Email";
        },
        password_errors() {
            let errors = [];
            if (this.password != this.password_verify && this.password !== "" && this.password_verify !== "") {
                errors.push("Passwords do not match!");
            }
            if (!/\d/.test(this.password)){
                errors.push("Password must contain a digit!");
            }
            if (!/[A-Z]/.test(this.password)){
                errors.push("Password must contain an uppercase letter!");
            }
            if (!/[a-z]/.test(this.password)){
                errors.push("Password must contain an lowercase letter!");
            }
            if (this.password.length < 12){
                errors.push("Password must be at least 12 characters long!");
            }
            if (this.password == "" || this.password_verify == "") {
                errors.push("Both password fields must be filled!");
            }
            return errors;
        },
        attribution_text_rules() {
            if (!this.attribution_other) {
                return {};
            }
            return {
                required: true,
                minlength: "5",
                maxlength: "100",
            }
        }
    }
}

</script>

<style>

.outer-shell {
    background-color: white;
    width: 100%;
    position:fixed;
    height: 100%;
    display: flex;
}

.login-pane, .promo-pane {
    flex: 1;
    margin: 30px;
}

.quote-holder {
    margin: 50px;
}

.login-pane {
    overflow: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.login-pane::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}

.authentication-form {
    display: block;
    max-width: 500px;
    margin: 5px auto 15px auto;
}

.form_input {
    display: block;
    width: 100%;
    border-radius: 10px;
    padding: 10px;

    border: 1px solid #D6D6D6;
    margin: 5px auto 15px auto;
    font-size: 22px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.form_input_checkbox {
    appearance: auto;
    margin: 5px;
}
.form_input_checkbox_text {
    font-weight: normal;
}
.form_input_checkbox_wrapper {
    display:flex;
    gap: 5px;
    margin: 5px 0;
}

.form_input::placeholder {
    color: #D6D6D6;
}

.login_button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 10px;


    margin: 0 auto 0 auto;
    border: 0;
    color: white;
    background-color: #0033cc;
    font-size: 1em;
    line-height: 1em;
    height: 42px;

    text-decoration: none;
}

.responses div {
    display: block;
    border-radius: 4px;
    padding: 20px;
    margin-top: 15px;
}

.responses .error {
    background-color: #FDF1F1;
    color: #852D3D;
}

.responses .success {
    background-color: #E6F8EF;
    color: #4D785E;
}

.errors {
    color: red;
}

label {
    color: #9FA1AB;
    display: block;
    text-align: left;
    font-weight: bold;
}

.login-pane .login-pane-title {
    text-align: center;
    font-size: 2em;
    margin-bottom: 50px;
}

.login-pane input:focus {
    outline-width: 0;
    outline: none;
}

@media (max-width: 1000px) {
    .promo-pane {
        display: none;
    }

    .login-pane, .promo-pane {
        margin: 20px;
    }

    .outer-shell {
        background-color: #F3F4F8;
    }

    form {
        width: 100%;
    }
}

.alternative {
    text-align: center;
    font-weight: bold;
    font-size: large;
}

.alternative a {
    color: #0033cc;
}

</style>
