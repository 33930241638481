import { createRouter, createWebHistory } from 'vue-router'
import Reports from "../views/Reports.vue"
import NewReport from "../views/NewReport.vue"
import Premium from "../views/Premium.vue"
import Report from "../views/Report.vue"
import Loading from "../views/Loading.vue"
import Verify from "../views/Auth/Verify.vue"
import Reset from "../views/Auth/Reset.vue"
import Index from "../views/Index.vue"

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Index,
        props: true,
    },
    {
        path: '/reports',
        name: 'Reports',
        component: Reports,
        props: true,
    },
    {
        path: '/reports/new',
        name: 'NewReport',
        component: NewReport,
        props: true,
    },
    {
        path: '/premium',
        name: 'Premium',
        component: Premium,
        props: true,
    },
    {
        path: '/report/:report_id',
        name: 'Report',
        component: Report,
        props: true,
    },
    {
        path: '/verify',
        name: 'Verify',
        component: Verify,
        props: true,
    },
    {
        path: '/reset',
        name: 'Reset',
        component: Reset,
        props: true,
    },
    {
        path: '/loading',
        name: 'Loading',
        component: Loading,
        props: true,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
