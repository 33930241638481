export const validation = {
    password_errors(password, password_verify) {
        let errors = [];
        if (password != password_verify && password !== "" && password_verify !== "") {
            errors.push("Passwords do not match!");
        }
        if (!/\d/.test(password)){
            errors.push("Password must contain a digit!");
        }
        if (!/[A-Z]/.test(password)){
            errors.push("Password must contain an uppercase letter!");
        }
        if (!/[a-z]/.test(password)){
            errors.push("Password must contain an lowercase letter!");
        }
        if (password.length < 12){
            errors.push("Password must be at least 12 characters long!");
        }
        if (password == "" || password_verify == "") {
            errors.push("Both password fields must be filled!");
        }
        return errors;
    }
}
