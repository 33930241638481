import { store } from "./store.js"
import router from "../router/index.js"

export const api = {
    async post(path, form) {
        let formData = new FormData();
        for (const key in form){
            formData.append(key, form[key]);
        }
        let res = await fetch(`${process.env.VUE_APP_API_URL}${path}`, {
            method: "POST",
            crossorigin: true,
            headers: {
                "Accept": "application/json",
                "Vue-Frontend-Version": process.env.VUE_APP_VERSION,
            },
            body: formData
        });
        return res;
    },
    async auth_post(path, form, photo=null) {
        let formData = new FormData();
        for (const key in form){
            formData.append(key, form[key]);
        }
        if (photo !== null) {
            formData.append( 'image', photo, "photo.png" );
        }
        let res = await fetch(`${process.env.VUE_APP_API_URL}${path}`, {
            method: "POST",
            withCredentials: true,
            crossorigin: true,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + store.bearer,
                "Vue-Frontend-Version": process.env.VUE_APP_VERSION,
            },
            body: formData
        });
        if (res.status == 401){
            router.push({name: "Login"});
        } else {
            return res;
        }
    },
    async auth_get(path) {
        let res = await fetch(`${process.env.VUE_APP_API_URL}${path}`, {
            method: "GET",
            withCredentials: true,
            crossorigin: true,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + store.bearer,
                "Vue-Frontend-Version": process.env.VUE_APP_VERSION,
            },
        });
        if (res.status == 401){
            store.handle_logout();
            router.push({name: "Login"});
        } else {
            return res;
        }
    }
}
